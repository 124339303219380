import Header from '../../NavBar/Header'

export const Contact = ({ user }) => {
  return (
    <div
      style={{
        overflowX: 'hidden',
        position: 'absolute',
        width: '100%',
        top: '-10px',
        bottom: '0',
      }}
    >
      <Header user={user} />
      <iframe
        style={{
          width: '100%',
          height: '100%',
        }}
        src='https://about.dentacloud.ai/contact-us'
        title='Contact Dentacloud'
      />
    </div>
  )
}
