import { onboardingPathPrefix } from './utils/constants'

const createPracticeFlowBaseUrl = '/my-practices/:practiceId'

export const Paths = {
  // ** API Endpoints **
  prodConnectBackend: 'https://octopus-app-s745k.ondigitalocean.app/graphql',
  stagingConnectBackend: 'https://seal-app-jtf73.ondigitalocean.app/graphql',
  localConnectBackend: 'http://localhost:4001/graphql',
  prodConnectBackendWebsocket:
    'wss://octopus-app-s745k.ondigitalocean.app/graphql',
  stagingConnectBackendWebsocket:
    'wss://seal-app-jtf73.ondigitalocean.app/graphql',
  localConnectBackendWebsocket: 'ws://localhost:4001/graphql',

  // ** External **
  privacyPolicy: 'https://dentacloud.ai/privacy/',

  // CMS site embeds
  about: '/about',
  blog: '/blog',
  contact: '/contact-us',

  // Assets
  whiteLogoWithHorizontalText:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/full-logo-horizontal-white.png',
  whiteLogoWithNoText:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/cloud-logo-white.png',
  blueLogoWithNoText:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/cloud-logo-blue.png',
  graphWithClouds:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/graph-with-clouds.png',
  bubbles:
    'https://mwa-assets.nyc3.cdn.digitaloceanspaces.com/assets/bubbles.svg',
  acquireWhatDoYouWantToDo:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/acquire-what-do-you-want-to-do.png',
  acquireConnectMetrics:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/acquire-connect-metrics.png',
  acquireTellUsAbout:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/acquire-tell-us-about.png',
  dentacloudPhonePage:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/phoneicon.png',
  dentacloudNamePage:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/handshake.png',
  dentacloudGoalsPage:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/buysellicon1.png',
  zillowHero:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/zillow-hero.png',
  practiceStockImage:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/dental-practice-stock-image.jpeg',
  handingKeys:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/handing-keys.png',
  houseCartoon:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/house-cartoon.png',
  waitlistThankYouMap:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/waitlist-thankyou-map-cropped.png',
  handshakeCartoon:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/handshake-asset.png',
  dentalClinicIcon:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/dental-clinic-icon.svg',
  dentalClinicIcon2:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/dental-clinic-icon-v2.svg',
  clinicWithCloudsBackgroundIcon:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/clinic-with-background-clouds.png',
  dentalChair:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/dental-chair-icon.svg',
  clusterChipSvg:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/cluster-chip.svg',

  // Example practice pictures
  exampleChair:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/practice_pictures/example/chair.png',
  exampleChairCloseUp:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/practice_pictures/example/chair-closeup.png',
  exampleComputer:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/practice_pictures/example/computer.png',
  exampleDesk:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/practice_pictures/example/desk.png',
  exampleFrontDesk:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/practice_pictures/example/front-desk.png',
  exampleMachine:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/practice_pictures/example/machine.png',
  exampleWaitingRoom:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/practice_pictures/example/waiting-room.png',
  emptyMap:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/empty-map.png',

  // ** Screens **

  // Auth
  callback: '/callback',

  // Direct access routes
  login: '/login',
  signup: '/signup',
  value: '/value',

  // Onboarding
  nameOnboarding: `${onboardingPathPrefix}name`,
  phoneOnboarding: `${onboardingPathPrefix}phone`,
  goalsOnboarding: `${onboardingPathPrefix}goals`,
  productOrServiceSubGoalOnboarding: `${onboardingPathPrefix}product-or-service-sub-goal`,
  isLicensedToPracticeOnboarding: `${onboardingPathPrefix}is-licensed`,
  dentalLicenseInfoOnboarding: `${onboardingPathPrefix}dental-license-info`,
  linkedinUrlOnboarding: `${onboardingPathPrefix}linkedin-url`,
  governmentIdOnboarding: `${onboardingPathPrefix}government-id`,

  // Main pages
  practices: '/practices',
  practicesMapSearch: '/practices?mapSearch=true',
  myPractices: '/my-practices',
  myValuations: '/my-valuations',
  sellOrBuy: '/sell-or-buy',
  myOffers: '/my-offers',
  myOffersPurchaseIntent: '/my-offers?page=purchase-intents',

  // View practice
  viewPractice: '/practices/:practiceId',

  // Create practice
  createPracticeStart: `${createPracticeFlowBaseUrl}/start`,
  createPracticeOverview: `${createPracticeFlowBaseUrl}/overview`,
  createPracticeTeam: `${createPracticeFlowBaseUrl}/your-team`,
  createPracticeLease: `${createPracticeFlowBaseUrl}/lease`,
  createPracticeTerms: `${createPracticeFlowBaseUrl}/your-terms`,
  createPracticeValuation: `${createPracticeFlowBaseUrl}/valuation`,
  createPracticePostOffMarket: `${createPracticeFlowBaseUrl}/off-market`,
  createPracticeDueDiligence: `${createPracticeFlowBaseUrl}/financials`,
  createPracticePublish: `${createPracticeFlowBaseUrl}/publish`,
  createPracticePublishedLandingPage: `${createPracticeFlowBaseUrl}/published`,

  // Waitlist
  waitlistThankYou: '/waitlist-thank-you',

  profile: '/profile',

  // Administrative panels
  practiceAdminPanel: '/practice-admin-panel',
  valuationAdminPanel: '/valuation-admin-panel',

  // Buyer Profile
  createBuyerProfile: '/buyers/:buyerId',

  // Create Insitutional Buyer Profile
  institutionalBuyerProfile: '/buyers-institutional/:buyerId',

  // Request for Access
  requestForAccess: '/buyers/:buyerId/request-for-access',

  // My Acquisition
  myAcquisition: '/buyers/:buyerId/my-acquisition',
  myAcquisitionPurchaseIntent:
    '/buyers/:buyerId/my-acquisition?page=purchase-intents',

  // Purchase Intent
  purchaseIntent: '/buyers/:buyerId/purchase-intent',
  // Purchase Intent
  dataroom: '/dataroom/:practiceId/:buyerId',

  // Link handler
  // Don't change this, the backend assumes it's /link/:linkId, and it'll break any links
  // that are already out there
  externalLinkHandler: '/link/:linkId',
  internalLinkHandler: '/internal-link',
}

Paths.default = Paths.practices

export default Paths
