import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Paths from '../../../Paths'
import { useNotifications } from '../../../api/hooks/notification'
import { useMyUser } from '../../../api/hooks/users'
import {
  MenuItemDivider,
  ParagraphText,
  StyledMenuItem,
} from '../../../styles/shared-styled-components'
import { userRoles } from '../../../utils/constants'
import { getFormattedUsername } from '../../../utils/naming'
import LoginButton from '../../Auth/LoginButton'
import SignupButton from '../../Auth/SignupButton/SignupButton'
import PopperMenu from '../../PopperMenu'
import NotificationsPopover from './NotificationsPopover'
import {
  BuyIcon,
  NotificationIconButton,
  NotificationIconInHeader,
  PracticeAdminPanelIcon,
  PracticeIcon,
  ProfileIconInHeader,
  ProfileLogoutIcon,
  ProfileMenuButton,
  ValuationAdminPanelIcon,
} from './styled'
import ArrowIcon from '../../Micro/ArrowIcon'
import {
  checkIfBuyGoalPresent,
  checkIfValueOrSellGoalPresent,
} from '../../../utils/user'
import { SectionContainer, SectionHeader } from '../Header/styled'
import { useBuyers } from '../../../api/hooks/buyers'
import { Autocomplete, TextField } from '@mui/material'
import { useQueryWithErrorLogging } from '../../../utils/hooks'
import { GET_USER_STAFF_VIEW } from '../../../api/queries/users'
import useLocalStorageState from 'use-local-storage-state'
import { ACT_AS_USER_LOCAL_STORAGE_KEY } from '../../../utils/constants'

function formatName(firstName, lastName) {
  if (!firstName && !lastName) {
    return 'Unknown'
  } else if (!firstName) {
    return lastName
  } else if (!lastName) {
    return firstName
  }
  return `${firstName} ${lastName}`
}

const ProfileDropdown = ({
  username,
  ismobilescreen,
  user: userData,
  isOnboardingScreen = false,
}) => {
  const [actAsUser, setActAsUser] = useLocalStorageState(
    ACT_AS_USER_LOCAL_STORAGE_KEY,
    null,
  )
  const navigate = useNavigate()
  const { notifications, refetch: refreshNotifications } =
    useNotifications(userData)
  const [searchParams] = useSearchParams()
  const { buyers } = useBuyers()

  const useMyUserResult = useMyUser()
  const { user, isAuthenticated, logout } = useMyUserResult.auth0Context
  const goals = useMyUserResult?.user?.goals

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [anchorNotifEl, setAnchorNotifEl] = useState(null)
  const openNotif = Boolean(anchorNotifEl)

  const { data, error, loading } = useQueryWithErrorLogging(
    GET_USER_STAFF_VIEW,
    {
      skip:
        user?.role !== userRoles.staff ||
        !useMyUserResult.auth0Context.isAuthenticated,
      fetchPolicy: 'network-only',
    },
  )

  if (error) {
    console.warn('Error loading user staff view', error)
  }

  useEffect(() => {
    if (openNotif && refreshNotifications) {
      refreshNotifications()
    }
  }, [refreshNotifications, openNotif])

  // Show first name
  const formattedUsername = username
    ? getFormattedUsername(username, ismobilescreen)
    : ''

  const handleProfileClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleNotifClick = (event) => {
    setAnchorNotifEl(event.currentTarget)
  }

  const handleClose = (event) => {
    if (!document.getElementById('profileMenuButton')?.contains(event.target)) {
      setAnchorEl(null)
    }
  }

  const handleCloseNofif = () => {
    setAnchorNotifEl(null)
  }

  const onLoginOrSignup = () => {
    const utmSource = searchParams.get('utm')

    if (utmSource) {
      localStorage.setItem('utm', utmSource)
    }
  }

  const onClickLogout = () => {
    window.localStorage.clear()
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  const hasUnreadNotifications = notifications?.some(
    (notification) => !notification.isRead,
  )

  const Contact = () => {
    navigate(Paths.contact)
  }

  const handleAboutUs = () => {
    navigate(Paths.about)
  }

  const handleBlog = () => {
    navigate(Paths.blog)
  }

  if (loading) {
    return null
  }

  const userNames =
    data?.getAllUsers.map((user) => ({
      id: user.id,
      label: formatName(user.firstName, user.lastName),
    })) || []

  return (
    <>
      {user?.role === userRoles.staff && !ismobilescreen && (
        <>
          <SectionContainer>
            <Autocomplete
              value={actAsUser}
              options={userNames}
              onChange={(_, newValue) => {
                setActAsUser(newValue)
              }}
              getOptionLabel={(item) => item.label}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id
              }}
              sx={{
                width: 300,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'white',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'white',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
                '& .MuiAutocomplete-input': {
                  color: 'white',
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Act as another user'
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.label}
                </li>
              )}
            />
          </SectionContainer>
        </>
      )}

      {!ismobilescreen && (
        <>
          <SectionContainer style={{ alignSelf: 'center' }}>
            <SectionHeader onClick={handleBlog}> Blog</SectionHeader>
          </SectionContainer>

          <SectionContainer style={{ alignSelf: 'center' }}>
            <SectionHeader onClick={handleAboutUs}>About&nbsp;Us</SectionHeader>
          </SectionContainer>

          <SectionContainer style={{ alignSelf: 'center' }}>
            <SectionHeader onClick={Contact}>Contact&nbsp;Us</SectionHeader>
          </SectionContainer>
        </>
      )}

      {isAuthenticated ? (
        <>
          <NotificationIconButton
            disablepadding='true'
            unread={hasUnreadNotifications}
            onClick={handleNotifClick}
            startIcon={<NotificationIconInHeader />}
          />

          {ismobilescreen ? (
            <ProfileMenuButton
              id='profileMenuButton'
              disableElevation
              // Have to spell it in lowercase so React doesn't confuse it for a built-in prop
              disablepadding='true'
              onClick={handleProfileClick}
              startIcon={<ProfileIconInHeader />}
            >
              {username}
              <ArrowIcon open={open} />
            </ProfileMenuButton>
          ) : (
            <ProfileMenuButton
              id='profileMenuButton'
              disableElevation
              // Have to spell it in lowercase so React doesn't confuse it for a built-in prop
              disablepadding='true'
              onClick={handleProfileClick}
              startIcon={<ProfileIconInHeader />}
            >
              {username} <ArrowIcon open={open} />
            </ProfileMenuButton>
          )}

          <NotificationsPopover
            notifications={notifications}
            anchorEl={anchorNotifEl}
            open={openNotif}
            onClose={handleCloseNofif}
          />
          <PopperMenu
            open={open}
            anchorElement={anchorEl}
            onClose={handleClose}
            variant='offset'
          >
            {/* {!isOnboardingScreen && (
              <>
                <StyledMenuItem
                  onClick={onClickProfile}
                  disableRipple
                >
                  <ProfileIconInDropdown />
                  <ParagraphText>Profile</ParagraphText>
                </StyledMenuItem>
                <MenuItemDivider />
              </>
            )} */}
            {user?.role === userRoles.staff && (
              <>
                <StyledMenuItem
                  onClick={() => navigate(Paths.practiceAdminPanel)}
                  disableRipple
                >
                  <PracticeAdminPanelIcon />
                  <ParagraphText>Practice Administration</ParagraphText>
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => navigate(Paths.valuationAdminPanel)}
                  disableRipple
                >
                  <ValuationAdminPanelIcon />
                  <ParagraphText>Valuation Model</ParagraphText>
                </StyledMenuItem>
                <MenuItemDivider />
              </>
            )}

            {checkIfBuyGoalPresent(goals) ? (
              <>
                <StyledMenuItem
                  onClick={() => {
                    setAnchorEl(null)
                    navigate(Paths.practices)
                  }}
                  disableRipple
                >
                  <BuyIcon />
                  <ParagraphText>Buy Your Dream Practice</ParagraphText>
                </StyledMenuItem>
              </>
            ) : (
              <></>
            )}
            {checkIfValueOrSellGoalPresent(goals) ? (
              <>
                <StyledMenuItem
                  onClick={() => {
                    setAnchorEl(null)
                    navigate(Paths.myPractices)
                  }}
                  disableRipple
                >
                  <PracticeIcon />
                  <ParagraphText>Practice Listings</ParagraphText>
                </StyledMenuItem>
              </>
            ) : (
              <></>
            )}
            <StyledMenuItem onClick={onClickLogout} disableRipple>
              <ProfileLogoutIcon />
              <ParagraphText>Logout</ParagraphText>
            </StyledMenuItem>
          </PopperMenu>
        </>
      ) : (
        <>
          <LoginButton user={user} variant='text' onClick={onLoginOrSignup} />
          <SignupButton
            variant='secondary'
            onClick={onLoginOrSignup}
            text='Get&nbsp;started'
          />
        </>
      )}
    </>
  )
}

export default ProfileDropdown
