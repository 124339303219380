import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import ApolloProviderWithToken from './ApolloProviderWithToken'
import Paths from './Paths'
import AuthRouter from './components/Auth/AuthRouter'
import AuthRouterV2 from './components/Auth/AuthRouterV2'
import CallbackPage from './pages/CallbackPage'
import DueDiligence from './pages/CreatePractice/DueDiligence'
import Lease from './pages/CreatePractice/Lease'
import Overview from './pages/CreatePractice/Overview'
import PostOffMarket from './pages/CreatePractice/PostOffMarket'
import Publish from './pages/CreatePractice/Publish'
import PublishedLandingPage from './pages/CreatePractice/PublishedLandingPage'
import Start from './pages/CreatePractice/Start'
import Team from './pages/CreatePractice/Team'
import Terms from './pages/CreatePractice/Terms'
import Valuation from './pages/CreatePractice/Valuation'
import MyOffers from './pages/MyOffers'
import MyPractices from './pages/MyPractices'
import MyValuations from './pages/MyValuations'
import DentalLicensingInfo from './pages/Onboarding/DentalLicensingInfo'
import Goals from './pages/Onboarding/Goals'
import IsLicensedToPractice from './pages/Onboarding/IsLicensedToPractice'
import LinkedinUrl from './pages/Onboarding/LinkedinUrl'
import Name from './pages/Onboarding/Name'
import Phone from './pages/Onboarding/Phone'
import ProductOrServiceSubGoal from './pages/Onboarding/ProductOrServiceSubGoal'
import Practices from './pages/Practices'
import SellOrBuy from './pages/SellOrBuy'
import ValuationAdminPanelPage from './pages/ValuationAdminPanel/ValuationAdminPanelPage'
import ViewPractice from './pages/ViewPractice'
import ExternalLinkHandler from './components/ExternalLinkHandler'
import WaitlistThankYou from './pages/Waitlist/WaitlistThankYou'
import CreateBuyerProfileForm from './pages/CreateBuyerProfile/CreateBuyerProfileForm/CreateBuyerProfileForm'
import Styles from './styles'
import BuyerProfile from './pages/BuyerProfile/BuyerProfile'
import BuyerProfileInstitutional from './pages/BuyerProfileInstitutional/BuyerProfile'
import RequestForAccess from './pages/RequestForAccess/RequestForAccess'
import MyAcquisition from './pages/MyAcquisition/MyAcquisition'
import PurchaseIntent from './pages/PurchaseIntent'
import Dataroom from './pages/Dataroom'
import PracticeAdminPanelPage from './pages/PracticeAdminPanel/PracticeAdminPanelPage'
import InternalLinkHandler from './components/InternalLinkHandler'
import { About } from './components/CMS/About'
import LoginPage from './pages/LoginPage'
import SignupPage from './pages/SignupPage'
import DirectValuation from './pages/DirectValuation'
import { Blog } from './components/CMS/Blog'
import { Contact } from './components/CMS/Contact'

const App = () => {
  return (
    <ApolloProviderWithToken>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={Styles}>
          <Routes>
            <Route path='*' element={<Navigate to={Paths.default} replace />} />
            <Route path='/' element={<AuthRouter Component={Practices} />} />
            {/* CMS Site Embeds */}
            <Route
              path={Paths.about}
              element={<AuthRouter Component={About} />}
            />
            {/* CMS Blog */}
            <Route
              path={Paths.blog}
              element={<AuthRouter Component={Blog} />}
            />
            {/* CMS Contact */}
            <Route
              path={Paths.contact}
              element={<AuthRouter Component={Contact} />}
            />
            {/* Auth */}
            <Route
              path={Paths.callback}
              element={<AuthRouter Component={CallbackPage} authGated />}
            />
            {/* Direct routes meant to be landing points */}
            <Route
              path={Paths.login}
              element={<AuthRouter Component={LoginPage} />}
            />
            <Route
              path={Paths.signup}
              element={<AuthRouter Component={SignupPage} />}
            />
            <Route
              path={Paths.value}
              element={<AuthRouter Component={DirectValuation} />}
            />
            {/* Onboarding */}
            <Route
              path={Paths.nameOnboarding}
              element={<AuthRouter Component={Name} authGated />}
            />
            <Route
              path={Paths.phoneOnboarding}
              element={<AuthRouter Component={Phone} authGated />}
            />
            <Route
              path={Paths.goalsOnboarding}
              element={<AuthRouter Component={Goals} authGated />}
            />
            <Route
              path={Paths.productOrServiceSubGoalOnboarding}
              element={
                <AuthRouter Component={ProductOrServiceSubGoal} authGated />
              }
            />
            <Route
              path={Paths.isLicensedToPracticeOnboarding}
              element={
                <AuthRouter Component={IsLicensedToPractice} authGated />
              }
            />
            <Route
              path={Paths.dentalLicenseInfoOnboarding}
              element={<AuthRouter Component={DentalLicensingInfo} authGated />}
            />
            <Route
              path={Paths.linkedinUrlOnboarding}
              element={<AuthRouter Component={LinkedinUrl} authGated />}
            />
            {/* Buy */}
            <Route
              path={Paths.practices}
              element={<AuthRouter Component={Practices} />}
            />
            {/* Sell */}
            <Route
              path={Paths.myPractices}
              element={<AuthRouter Component={MyPractices} authGated />}
            />
            <Route
              path={Paths.myValuations}
              element={<AuthRouter Component={MyValuations} authGated />}
            />
            {/* View practice */}
            <Route
              path={Paths.viewPractice}
              element={<AuthRouter Component={ViewPractice} />}
            />
            {/* Buyer Profile */}
            <Route
              path={Paths.createBuyerProfile}
              element={<AuthRouter Component={BuyerProfile} authGated />}
            />
            {/*Institutional */}
            <Route
              path={Paths.institutionalBuyerProfile}
              element={
                <AuthRouter Component={BuyerProfileInstitutional} authGated />
              }
            />
            {/* Request for Access */}
            <Route
              path={Paths.requestForAccess}
              element={<AuthRouter Component={RequestForAccess} authGated />}
            />
            {/* My Acquisition */}
            <Route
              path={Paths.myAcquisition}
              element={<AuthRouterV2 Component={MyAcquisition} authGated />}
            />
            {/* Dataroom */}
            <Route
              path={Paths.dataroom}
              element={<AuthRouter Component={Dataroom} authGated />}
            />
            {/* Purchase Intent */}
            <Route
              path={Paths.purchaseIntent}
              element={<AuthRouter Component={PurchaseIntent} authGated />}
            />
            {/* My offers */}
            <Route
              path={Paths.myOffers + '/:practiceId?'}
              element={<AuthRouterV2 Component={MyOffers} authGated />}
            />
            {/*** Create practice flow ***/}
            <Route
              path={Paths.createPracticeStart}
              element={<AuthRouter Component={Start} authGated />}
            />
            <Route
              path={Paths.createPracticeOverview}
              element={<AuthRouter Component={Overview} authGated />}
            />
            <Route
              path={Paths.createPracticeTeam}
              element={<AuthRouter Component={Team} authGated />}
            />
            <Route
              path={Paths.createPracticeLease}
              element={<AuthRouter Component={Lease} authGated />}
            />
            <Route
              path={Paths.createPracticeTerms}
              element={<AuthRouter Component={Terms} authGated />}
            />
            <Route
              path={Paths.createPracticeValuation}
              element={<AuthRouter Component={Valuation} authGated />}
            />
            <Route
              path={Paths.createPracticePostOffMarket}
              element={<AuthRouter Component={PostOffMarket} authGated />}
            />
            <Route
              path={Paths.createPracticeDueDiligence}
              element={<AuthRouter Component={DueDiligence} authGated />}
            />
            <Route
              path={Paths.createPracticePublish}
              element={<AuthRouter Component={Publish} authGated />}
            />
            <Route
              path={Paths.createPracticePublishedLandingPage}
              element={
                <AuthRouter Component={PublishedLandingPage} authGated />
              }
            />
            {/* Sell or Buy */}
            <Route
              path={Paths.sellOrBuy}
              element={<AuthRouter Component={SellOrBuy} />}
            />
            {/* Waitlist */}
            <Route
              path={Paths.waitlistThankYou}
              element={<AuthRouter Component={WaitlistThankYou} />}
            />
            <Route
              path={Paths.valuationAdminPanel}
              element={
                <AuthRouter authGated Component={ValuationAdminPanelPage} />
              }
            />
            <Route
              path={Paths.practiceAdminPanel}
              element={
                <AuthRouter authGated Component={PracticeAdminPanelPage} />
              }
            />

            {/* Create Buyer Profile */}
            <Route
              path={Paths.createBuyerProfile}
              element={
                <AuthRouter Component={CreateBuyerProfileForm} authGated />
              }
            />

            <Route
              path={Paths.externalLinkHandler}
              element={<AuthRouter authGated Component={ExternalLinkHandler} />}
            />
            <Route
              path={Paths.internalLinkHandler}
              element={<AuthRouter authGated Component={InternalLinkHandler} />}
            />
          </Routes>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProviderWithToken>
  )
}

export default App
