import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../../Paths'
import { useBuyers } from '../../../api/hooks/buyers'
import useToggleState from '../../../custom-hooks/use-toggle-state'
import { hasAtleastOneCompleteProfile } from '../../../pages/ViewPractice/ViewPractice'
import { PageContainer } from '../../../styles/shared-styled-components'
import {
  addMapSearchToSearchParams,
  defaultHomePath,
  removeMapSearchFromSearchParams,
} from '../../../utils/routing'
import {
  screenWidthIsIpadSizeOrSmaller,
  screenWidthIsMediumMobileSizeOrSmaller,
  screenWidthIsMobileSizeOrSmaller,
  useWindowWidth,
} from '../../../utils/view'
import ProfileDropdown from '../ProfileDropdown'
import {
  AppBar,
  CloseIcon,
  CloseIconContainer,
  DesktopHeaderDentacloudLogo,
  DesktopNavToolbar,
  DrawerIcon,
  DrawerIconContainer,
  FullScreenHeader,
  HorizontalSectionMenuDivider,
  MobileHeaderDentacloudLogo,
  MobileHeaderDentacloudLogoContainer,
  MobileNavToolbar,
  MobileSection,
  MobileSectionHeader,
  MobileSectionHeaderTextContainer,
  MobileSectionsContainer,
  SectionContainer,
  SectionHeader,
  SectionHeaderTextContainer,
  ToolbarContainerCenter,
  ToolbarContainerLeft,
  ToolbarContainerRight,
} from './styled'

const Header = ({
  user,
  isOnboardingScreen = false,
  showProfileDropdown = true,
  selectedDrawerOption = null,
}) => {
  const width = useWindowWidth()
  const ismobilescreen = screenWidthIsMobileSizeOrSmaller(width)
  const isIpadScreenOrSmaller = screenWidthIsIpadSizeOrSmaller(width)
  const isMediumMobileScreenOrSmaller =
    screenWidthIsMediumMobileSizeOrSmaller(width)

  const { buyers } = useBuyers()
  const navigate = useNavigate()
  const isAuthenticated = !!user

  const [anchorElementBuy, setAnchorElementBuy] = useState(null)
  const [anchorElementSell, setAnchorElementSell] = useState(null)
  const [anchorElementVal, setAnchorElementVal] = useState(null)
  const [anchorElementServices, setAnchorElementServices] = useState(null)
  const [drawerOpen, toggleDrawerOpen] = useToggleState(false)

  const onClickLogo = () => {
    if (selectedDrawerOption) {
      console.log(selectedDrawerOption)
      navigate(selectedDrawerOption)
    } else {
      console.log(defaultHomePath)
      navigate({
        pathname: defaultHomePath,
        search: removeMapSearchFromSearchParams(window.location.search),
      })
      if (drawerOpen) {
        toggleDrawerOpen()
      }
    }
  }

  const username = user?.firstName

  const sellSectionOpen = Boolean(anchorElementSell)
  const buySectionOpen = Boolean(anchorElementBuy)
  const valueSectionOpen = Boolean(anchorElementVal)
  const servicesSectionOpen = Boolean(anchorElementServices)
  const headerSectionOpen =
    sellSectionOpen || buySectionOpen || servicesSectionOpen

  // Making sure we close any open header section when we shrink the screen from full header to mobile header
  useEffect(() => {
    if (isMediumMobileScreenOrSmaller && headerSectionOpen) {
      if (sellSectionOpen) {
        setAnchorElementSell(null)
      } else if (buySectionOpen) {
        setAnchorElementBuy(null)
      } else if (servicesSectionOpen) {
        setAnchorElementServices(null)
      } else if (valueSectionOpen) {
        setAnchorElementVal(null)
      }
    }
  }, [isMediumMobileScreenOrSmaller])

  const handleAboutUs = () => {
    toggleDrawerOpen(false)
    navigate(Paths.about)
  }

  const handleBlog = () => {
    toggleDrawerOpen(false)
    navigate(Paths.blog)
  }

  const Contact = () => {
    toggleDrawerOpen(false)
    navigate(Paths.contact)
  }

  const buyerTrue = hasAtleastOneCompleteProfile(buyers)
  const buyerId = buyers[0]?.id

  return (
    <>
      {isOnboardingScreen ? (
        <AppBar>
          <DesktopNavToolbar disableGutters>
            <ToolbarContainerLeft ismobilescreen={ismobilescreen}>
              <DesktopHeaderDentacloudLogo
                ismobilescreen={isMediumMobileScreenOrSmaller}
                component='img'
                alt='Dentacloud Logo'
                src={
                  isMediumMobileScreenOrSmaller
                    ? Paths.whiteLogoWithNoText
                    : Paths.whiteLogoWithHorizontalText
                }
                onClick={() => onClickLogo()}
              />
            </ToolbarContainerLeft>
            <ToolbarContainerCenter />
            <ToolbarContainerRight>
              {showProfileDropdown && (
                <ProfileDropdown
                  user={user}
                  username={username}
                  isAuthenticated={isAuthenticated}
                  ismobilescreen={ismobilescreen}
                  isOnboardingScreen={isOnboardingScreen}
                />
              )}
            </ToolbarContainerRight>
          </DesktopNavToolbar>
        </AppBar>
      ) : (
        <>
          <AppBar>
            {/* The desktop header */}
            <DesktopNavToolbar disableGutters>
              <ToolbarContainerLeft ismobilescreen={ismobilescreen}>
                {isMediumMobileScreenOrSmaller && (
                  <DrawerIconContainer onClick={toggleDrawerOpen} disableRipple>
                    <DrawerIcon />
                  </DrawerIconContainer>
                )}
                <DesktopHeaderDentacloudLogo
                  ismobilescreen={isMediumMobileScreenOrSmaller}
                  component='img'
                  alt='Dentacloud Logo'
                  src={
                    isMediumMobileScreenOrSmaller
                      ? Paths.whiteLogoWithNoText
                      : Paths.whiteLogoWithHorizontalText
                  }
                  onClick={() => onClickLogo()}
                />
                {!isMediumMobileScreenOrSmaller && (
                  <>
                    <SectionContainer>
                      <SectionHeader
                        onClick={() => navigate(Paths.myPractices)}
                      >
                        <SectionHeaderTextContainer>
                          Value
                        </SectionHeaderTextContainer>
                      </SectionHeader>
                      <SectionHeader
                        onClick={() => navigate(Paths.myPractices)}
                      >
                        <SectionHeaderTextContainer>
                          Sell
                        </SectionHeaderTextContainer>
                      </SectionHeader>

                      <SectionHeader
                        onClick={() =>
                          navigate({
                            pathname: Paths.practices,
                            search: addMapSearchToSearchParams(
                              window.location.search,
                            ),
                          })
                        }
                      >
                        <SectionHeaderTextContainer>
                          Buy
                        </SectionHeaderTextContainer>
                      </SectionHeader>
                      {isAuthenticated && (
                        <SectionHeader
                          onClick={
                            buyerTrue
                              ? () =>
                                  navigate(
                                    Paths.myAcquisition.replace(
                                      ':buyerId',
                                      buyerId,
                                    ),
                                  )
                              : () =>
                                  alert(
                                    'To access this tab please make a dataroom request on a practice',
                                  )
                          }
                        >
                          <SectionHeaderTextContainer>
                            My Deals
                          </SectionHeaderTextContainer>
                        </SectionHeader>
                      )}
                    </SectionContainer>
                  </>
                )}
              </ToolbarContainerLeft>
              {!isIpadScreenOrSmaller && <ToolbarContainerCenter />}
              <ToolbarContainerRight>
                {showProfileDropdown && (
                  <ProfileDropdown
                    username={username}
                    isAuthenticated={isAuthenticated}
                    ismobilescreen={ismobilescreen}
                    user={user}
                  />
                )}
              </ToolbarContainerRight>
            </DesktopNavToolbar>
          </AppBar>
          {/* The fullscreen mobile header that's opened once the user clicks on the drawer */}
          <FullScreenHeader open={drawerOpen}>
            <AppBar>
              <MobileNavToolbar disableGutters>
                <ToolbarContainerLeft ismobilescreen={ismobilescreen}>
                  <CloseIconContainer onClick={toggleDrawerOpen}>
                    <CloseIcon />
                  </CloseIconContainer>
                  <MobileHeaderDentacloudLogoContainer>
                    <MobileHeaderDentacloudLogo
                      ismobilescreen
                      component='img'
                      alt='Dentacloud Logo'
                      src={Paths.blueLogoWithNoText}
                      onClick={() => onClickLogo()}
                    />
                  </MobileHeaderDentacloudLogoContainer>
                </ToolbarContainerLeft>
                <ToolbarContainerCenter />
                <ToolbarContainerRight />
              </MobileNavToolbar>
            </AppBar>
            <PageContainer>
              <MobileSectionsContainer>
                <MobileSection
                  onClick={() => {
                    toggleDrawerOpen(false)
                    navigate(Paths.myPractices)
                  }}
                >
                  <MobileSectionHeader>
                    <MobileSectionHeaderTextContainer>
                      Value
                    </MobileSectionHeaderTextContainer>
                  </MobileSectionHeader>
                </MobileSection>
                <HorizontalSectionMenuDivider />

                <MobileSection
                  onClick={() => {
                    toggleDrawerOpen(false)
                    navigate(Paths.myPractices)
                  }}
                >
                  <MobileSectionHeader>
                    <MobileSectionHeaderTextContainer>
                      Sell
                    </MobileSectionHeaderTextContainer>
                  </MobileSectionHeader>
                </MobileSection>
                <HorizontalSectionMenuDivider />
                <MobileSection
                  onClick={() => {
                    toggleDrawerOpen(false)
                    navigate({
                      pathname: Paths.practices,
                      search: addMapSearchToSearchParams(
                        window.location.search,
                      ),
                    })
                  }}
                >
                  <MobileSectionHeader>
                    <MobileSectionHeaderTextContainer>
                      Buy
                    </MobileSectionHeaderTextContainer>
                  </MobileSectionHeader>
                </MobileSection>
                <HorizontalSectionMenuDivider />
                {isAuthenticated && (
                  <>
                    <MobileSection
                      onClick={
                        buyerTrue
                          ? () => {
                              toggleDrawerOpen(false)
                              navigate(
                                Paths.myAcquisition.replace(
                                  ':buyerId',
                                  buyerId,
                                ),
                              )
                            }
                          : () =>
                              alert(
                                'To access this tab please make a dataroom request on a practice',
                              )
                      }
                    >
                      <MobileSectionHeader>
                        <MobileSectionHeaderTextContainer>
                          My Deals
                        </MobileSectionHeaderTextContainer>
                      </MobileSectionHeader>
                    </MobileSection>
                    <HorizontalSectionMenuDivider />
                  </>
                )}
                <MobileSection onClick={handleAboutUs}>
                  <MobileSectionHeader>
                    <MobileSectionHeaderTextContainer>
                      About Us
                    </MobileSectionHeaderTextContainer>
                  </MobileSectionHeader>
                </MobileSection>
                <HorizontalSectionMenuDivider />
                <MobileSection onClick={Contact}>
                  <MobileSectionHeader>
                    <MobileSectionHeaderTextContainer>
                      Contact Us
                    </MobileSectionHeaderTextContainer>
                  </MobileSectionHeader>
                </MobileSection>
                <HorizontalSectionMenuDivider />
                <MobileSection onClick={handleBlog}>
                  <MobileSectionHeader>
                    <MobileSectionHeaderTextContainer>
                      Blog
                    </MobileSectionHeaderTextContainer>
                  </MobileSectionHeader>
                </MobileSection>
              </MobileSectionsContainer>
            </PageContainer>
          </FullScreenHeader>
        </>
      )}
    </>
  )
}

export default Header
